
















import { Component, Vue } from "vue-property-decorator";
import energyBoxesStore, {
  EnergyBoxesStoreState,
} from "@/stores/energyBoxesStore";
import { routeToBox } from "@/router/router";
import { EnergyBox } from "@/models/EnergyBox";

@Component
export default class History extends Vue {
  energyBoxesStoreState: EnergyBoxesStoreState = energyBoxesStore.state;

  boxNumnber: number | null = null;

  get boxes(): EnergyBox[] {
    return this.energyBoxesStoreState.boxes;
  }

  search(): void {
    if (!this.boxNumnber || this.boxNumnber <= 0) {
      this.errorNotify(this.$t('search.le-numero-de-borne-doit-etre-greater-than-1'), new Error());
      return;
    }

    for (const box of this.boxes) {
      if (box.localisation == this.boxNumnber) {
        routeToBox(this.boxNumnber);
        return;
      }
    }

    this.errorNotify(`${this.boxNumnber}` + this.$t('search.nest-pas-un-numero-de-borne'), new Error());
  }

  async refresh(): Promise<void> {
    try {
      energyBoxesStore.refresh();
    } catch (e) {
      this.errorNotify(this.$t('search.impossible-de-charger-lhistorique'), e as Error);
    }
  }

  mounted(): void {
    this.refresh();
  }
}
