var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-container', {
    staticClass: "pa-4"
  }, [_c('v-row', {
    staticClass: "my-4",
    attrs: {
      "justify": "center"
    }
  }, [_c('div', {
    staticClass: "text-h4"
  }, [_vm._v(_vm._s(_vm.$t('search.chercher-une-borne')))])]), _c('v-row', [_c('v-col', {
    staticClass: "col-8"
  }, [_c('v-text-field', {
    attrs: {
      "type": "number",
      "label": this.$t('search.numero-de-la-borne')
    },
    model: {
      value: _vm.boxNumnber,
      callback: function callback($$v) {
        _vm.boxNumnber = $$v;
      },
      expression: "boxNumnber"
    }
  })], 1), _c('v-col', {
    staticClass: "col-col-4"
  }, [_c('v-btn', {
    attrs: {
      "prepend-icon": "mdi-magnify",
      "color": "primary"
    },
    on: {
      "click": function click($event) {
        return _vm.search();
      }
    }
  }, [_vm._v(_vm._s(_vm.$t('search.chercher')))])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }